import { render, staticRenderFns } from "./name.vue?vue&type=template&id=05f03200&scoped=true&"
import script from "./name.vue?vue&type=script&lang=js&"
export * from "./name.vue?vue&type=script&lang=js&"
import style0 from "./name.vue?vue&type=style&index=0&id=05f03200&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f03200",
  null
  
)

export default component.exports