import { render, staticRenderFns } from "./PrizeDetailFrame.vue?vue&type=template&id=43071ffe&scoped=true&"
import script from "./PrizeDetailFrame.vue?vue&type=script&lang=js&"
export * from "./PrizeDetailFrame.vue?vue&type=script&lang=js&"
import style0 from "./PrizeDetailFrame.vue?vue&type=style&index=0&id=43071ffe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43071ffe",
  null
  
)

export default component.exports