var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.campaign && _vm.canImportProgress)?_c('div',{attrs:{"id":"import-progress"}},[(_vm.status != null)?_c('div',[_c('a',{staticClass:"btn btn-link",attrs:{"href":"https://vivi-public-files.s3.ap-southeast-1.amazonaws.com/files/progressbar/monthly-progress-upload_jiffy.xlsx"}},[_vm._v(" Click to download example ")]),(_vm.status.active === 0)?_c('div',{staticClass:"wrapper-upload-form"},[_c('label',{staticClass:"btn btn-round btn-upload btn-primary",attrs:{"for":"user-target-reward-file-upload","disabled":_vm.disabled}},[_vm._v(" Import Progress ")]),_c('input',{staticClass:"input-select-file",attrs:{"type":"file","disabled":_vm.disabled,"id":"user-target-reward-file-upload","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},on:{"change":_vm.onFileSelected}})]):(
        _vm.status.active === 1 &&
        _vm.status.type == 'progress' &&
        _vm.status.state != 'execute'
      )?_c('div',{staticClass:"wrapper-upload-form"},[(_vm.status.state == 'verify')?_c('label',{staticClass:"label-blue"},[_vm._v(" Verifying progress ")]):(_vm.status.state == 'done-verify')?_c('label',{staticClass:"label-blue"},[_vm._v(" Progress verified ")]):_vm._e(),(_vm.status.state == 'verify')?_c('div',[_c('button',{staticClass:"btn btn-round btn-primary mx-10",attrs:{"type":"button","disabled":""}},[_vm._v(" Commit ")]),_c('button',{staticClass:"btn btn-round btn-default",attrs:{"type":"button","disabled":""}},[_vm._v(" Cancel ")])]):(_vm.status.state == 'done-verify')?_c('div',[_c('button',{staticClass:"btn btn-round btn-primary mx-10",attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.getResult}},[_vm._v(" Commit ")]),_c('button',{staticClass:"btn btn-round btn-default",attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.cancelProcess}},[_vm._v(" Cancel ")])]):_vm._e()]):(
        _vm.status.active === 1 &&
        _vm.status.type == 'progress' &&
        _vm.status.state == 'execute'
      )?_c('div',{staticClass:"wrapper-upload-form"},[_c('label',[_vm._v(" "+_vm._s(_vm.filename)+" ")]),_c('label',{staticClass:"label-blue"},[_vm._v(" Processing progress. ")]),_c('button',{staticClass:"btn btn-round btn-primary",attrs:{"type":"button","disabled":""}},[_vm._v(" Edit ")])]):_vm._e()]):_vm._e(),_c('modal',{attrs:{"name":"try-upload-progress-modal","width":900,"height":"auto"}},[_c('TryUploadModal',{attrs:{"campaign":_vm.campaign,"result":_vm.result,"name":"try-upload-modal"},on:{"confirm":_vm.confirmUpload}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }