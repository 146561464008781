import { render, staticRenderFns } from "./TargetAndReward.vue?vue&type=template&id=e673acd0&scoped=true&"
import script from "./TargetAndReward.vue?vue&type=script&lang=js&"
export * from "./TargetAndReward.vue?vue&type=script&lang=js&"
import style0 from "./TargetAndReward.vue?vue&type=style&index=0&id=e673acd0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e673acd0",
  null
  
)

export default component.exports